@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: 'Encode Sans', sans-serif;
  color: '#212529';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: '#f8f9fa';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  all: unset;
}

@font-face {
  font-family: 'Heyam';
  src:
    local('Heyam'),
    url(./assets/fonts/Heyam.ttf) format('truetype');
}
